define('due-dashboard/components/v2/libraries/surveys/navbar/elements/element-content', ['exports', 'ember', 'due-dashboard/components/v2/libraries/surveys/navbar/elements/common/closed-questions'], function (exports, _ember, _dueDashboardComponentsV2LibrariesSurveysNavbarElementsCommonClosedQuestions) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var copy = _ember['default'].copy;
  var set = _ember['default'].set;
  var get = _ember['default'].get;
  exports['default'] = Component.extend({
    classNames: ['surveys-library-navbar-content'],
    elementName: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.initialObjectCopy();
    },

    didUpdateAttrs: function didUpdateAttrs() {
      this.initialObjectCopy();
    },

    initialObjectCopy: function initialObjectCopy() {
      var step = this.get('selectedStepIndex');
      var elem = this.get('selectedElementIndex');
      var savedStep = this.get('savedStepIndex');
      var savedElem = this.get('savedElementIndex');

      if (step !== savedStep || elem !== savedElem) {
        // means element has changed
        this.set('savedStepIndex', step);
        this.set('savedElementIndex', elem);
        this.set('savedObject', copy(this.get('object'), true));
      }
    },

    conditionSourceFilterInput: '',

    CONDITION_KINDS: ['contains', 'does_not_contains', 'is_any_of', 'is_not_one_of'],

    CONDITIONABLE_QUESTION_TYPES: ['rating', 'short_text_input', 'long_text_input', 'single_select', 'multiple_select'],
    EXECUTABLE_ELEMENT_KINDS: ['group', 'assign_segment', 'submit_step', 'soft_complete'],

    executableElement: computed('', function () {
      var execElements = this.get('EXECUTABLE_ELEMENT_KINDS');
      var object = this.get('object');

      return execElements.includes(object.kind);
    }),

    dasherizedKind: computed('object.kind', function () {
      var kind = this.get('object.kind');

      return kind.replace('_', '-');
    }),

    dropdownConditionActions: computed(function () {
      return [{ color: ' ', icon: 'tio-copy', action: 'duplicateCondition', text: this.get('i18n').t('words.duplicate_maj') }, { color: ' ', icon: 'tio-delete', action: 'deleteCondition', text: this.get('i18n').t('words.delete_maj') }];
    }),

    conditionSourceOptions: computed('group.segmentsList', 'group.questionsList', 'conditionSourceFilterInput', function () {
      var _this = this;

      var conditionableQuestionsTypes = this.get('CONDITIONABLE_QUESTION_TYPES');
      var filter = this.get('conditionSourceFilterInput');
      var filterParentKey = this.get('conditionSourceFilterKey');
      var questions = (filter.length ? this.get('group.questionsList').filter(function (question) {
        return question.text.toLowerCase().includes(filter.toLowerCase()) && conditionableQuestionsTypes.includes(question.kind);
      }) : this.get('group.questionsList').filter(function (question) {
        return conditionableQuestionsTypes.includes(question.kind);
      })).filter(function (question) {
        return question.step <= _this.get('selectedStepIndex');
      });
      var segments = filter.length ? this.get('group.segmentsList').filter(function (segment) {
        return segment.text.toLowerCase().includes(filter.toLowerCase());
      }) : this.get('group.segmentsList');

      if (filter.length > 0) {
        return filterParentKey === 'segments' ? segments : questions.map(function (question) {
          return { text: question.step + 1 + '.' + (question.element + 1) + ' ' + question.text, type: 'question', step: question.step, element: question.element };
        });
      } else {
        return [{ text: this.get('i18n').t('words.segments').string, options: segments, key: 'segments' }, { text: this.get('i18n').t('words.questions').string, key: 'questions', options: questions.map(function (question) {
            return { text: question.step + 1 + '.' + (question.element + 1) + ' ' + question.text, type: 'question', step: question.step, element: question.element, content: question.content };
          }) }];
      }
    }),

    tabOptions: computed('object.conditions', 'object', function () {
      var object = this.get('object');
      var conditions = object.conditions.filter(function (condition) {
        if (condition.data[condition.kind] && condition.data[condition.kind].length > 0 && (condition.data.answer_element != null || condition.data.segment_name != null)) {
          return true;
        }
      });
      var tabName = ({
        title: 'title',
        text: 'text',
        short_text_input: 'text',
        long_text_input: 'text',
        single_select: 'question',
        multiple_select: 'question',
        rating: 'question',
        prev_next_btns: 'buttons',
        redirect_btn: 'button',
        soft_complete: 'checkpoint',
        assign_segment: 'segment',
        submit_step: 'jump_to'
      })[object.kind];
      return [{ label: this.get('i18n').t('words.' + tabName) }, { label: this.get('i18n').t('words.conditions'), badge: conditions.length }];
    }),

    conditionsDataMapping: {
      'answer_in': {
        answer_step: 0,
        answer_element: 0,
        answer_in: []
      },
      'segment_value_in': {
        segment_name: '',
        segment_value_in: []
      },
      'segment_value_not_in': {
        segment_name: '',
        segment_value_not_in: []
      },
      'segment_value_contains': {
        segment_name: '',
        segment_value_contains: []
      },
      'segment_value_not_contains': {
        segment_name: '',
        segment_value_not_contains: []
      }
    },

    cmpElementIndex: computed('selectedElementIndex', function () {
      var _this2 = this;

      var elemIndex = this.get('selectedElementIndex');
      var object = this.get('object');

      if (object.kind === 'group') {
        var subElement = object.child_indexes_info.filter(function (childUuid) {
          return childUuid === _this2.get('object.uuid');
        })[0];
        if (subElement) {
          elemIndex = subElement.idx;
        }
      }

      return elemIndex;
    }),

    computedElementConditions: computed('object.conditions', function () {
      var _this3 = this;

      var object = this.get('object');

      return get(object, 'conditions').map(function (condition) {
        var questionList = _dueDashboardComponentsV2LibrariesSurveysNavbarElementsCommonClosedQuestions['default']._getQuestionSource.bind(_this3)(condition);
        return {
          kind: condition.kind,
          cmpType: _this3._getCmpType(condition),
          cmpSource: _this3._getCmpSource(condition),
          data: condition.data,
          questionSource: questionList,
          questionSourceRemaining: [].concat(_toConsumableArray(questionList.filter(function (q) {
            return !(condition.data.answer_in || []).some(function (c) {
              return q.key == c;
            });
          })))
        };
      });
    }),

    _computeConditionKind: function _computeConditionKind(kind, condition) {
      if (condition.data.segment_name) {
        switch (kind) {
          case 'is_any_of':
            return 'segment_value_in';
          case 'is_not_one_of':
            return 'segment_value_not_in';
          case 'contains':
            return 'segment_value_contains';
          case 'does_not_contains':
            return 'segment_value_not_contains';
        }
      } else {
        return 'contains';
      }
    },

    _getCmpType: function _getCmpType(condition) {
      switch (condition.kind) {
        case 'answer_in':
          return 'is_any_of';
        case 'segment_value_in':
          return 'is_any_of';
        case 'segment_value_not_in':
          return 'is_not_one_of';
        case 'segment_value_contains':
          return 'contains';
        case 'segment_value_not_contains':
          return 'does_not_contains';
      }
    },

    _getCmpSource: function _getCmpSource(condition) {
      var questions = this.get('group.questionsList');

      if (condition.kind.includes('segment_value_')) {
        return { text: condition.data.segment_name };
      } else if (condition.kind === 'answer_in') {
        var _ret = (function () {
          var step = condition.data.answer_step;
          var element = condition.data.answer_element;
          var question = questions.filter(function (question) {
            return question.step === step && question.element === element;
          })[0].text;

          return {
            v: { text: step + 1 + '.' + (element + 1) + ' ' + question }
          };
        })();

        if (typeof _ret === 'object') return _ret.v;
      }
    },

    actions: {
      dispatchAction: function dispatchAction(action, arg) {
        var dispatcher = this.get('actionsDispatcher');

        if (dispatcher) {
          dispatcher(action, arg);
        }
      },

      addCondition: function addCondition() {
        var object = this.get('object');
        var newCondition = { uuid: crypto.randomUUID(), kind: '', data: {} };
        var childConditions = object.conditions;

        childConditions.push(newCondition);
        set(object, 'conditions', childConditions);
        this.set('unsavedChanges', true);
        this.notifyPropertyChange('object.conditions');
      },

      setConditionKind: function setConditionKind(index, kind) {
        var object = this.get('object');
        var condition = get(object, 'conditions.' + index);
        var values = condition.data[condition.kind];
        var newKind = this._computeConditionKind(kind, condition);

        set(object, 'conditions.' + index + '.kind', newKind);

        set(object, 'conditions.' + index + '.data.' + newKind, values);
        this.set('unsavedChanges', true);
        this.notifyPropertyChange('object.conditions');
      },

      setConditionSource: function setConditionSource(index, source) {
        var object = this.get('object');
        var conditionsDataMapping = this.get('conditionsDataMapping');

        if (source.type === 'question') {
          var conditionStepIdx = source.step;
          var conditionElemIdx = source.element;

          var condition = get(object, 'conditions.' + index);
          var values = condition.data[condition.kind] || [];
          values.clear();

          set(object, 'conditions.' + index, { kind: '', data: {} });
          set(object, 'conditions.' + index + '.kind', 'answer_in');
          set(object, 'conditions.' + index + '.data', copy(conditionsDataMapping['answer_in'], true));
          set(object, 'conditions.' + index + '.data.answer_step', parseInt(conditionStepIdx));
          set(object, 'conditions.' + index + '.data.answer_element', parseInt(conditionElemIdx));
          set(object, 'conditions.' + index + '.data.answer_in', values);
        } else {
          var condition = get(object, 'conditions.' + index);
          var values = condition.data[condition.kind] || [];
          var newKind = condition.kind.includes('answer') ? 'segment_value_in' : source.kind || 'segment_value_in';

          set(object, 'conditions.' + index, { kind: '', data: {} });
          set(object, 'conditions.' + index + '.kind', newKind);
          set(object, 'conditions.' + index + '.data', copy(conditionsDataMapping[newKind], true));
          set(object, 'conditions.' + index + '.data.segment_name', source.text);
          set(object, 'conditions.' + index + '.data.' + newKind, values);
        }
        this.set('unsavedChanges', true);
        this.notifyPropertyChange('object.conditions');
      },

      setQuestionSource: function setQuestionSource(options, index, question) {
        var object = this.get('object');
        var condition = object.conditions[index];
        var values = condition.data[condition.kind] || [];
        if (values.findIndex(function (v) {
          return v == question.key;
        }) == -1) {
          values.push(question.key);
          set(options, 'questionSourceRemaining', [].concat(_toConsumableArray(options.questionSourceRemaining.toSpliced(options.questionSourceRemaining.findIndex(function (o) {
            return o.key == question.key;
          }), 1))));
          this.send('addConditionValue', null, index, values);
        }
      },

      toggleDropdown: function toggleDropdown(onToggle) {
        onToggle();
      },

      duplicateCondition: function duplicateCondition(index) {
        var duplicateCondition = copy(get(object, 'conditions.' + index), true);
        var object = this.get('object');

        var conditions = object.conditions;
        conditions.splice(index + 1, 0, duplicateCondition);

        set(object, 'conditions', conditions);
        this.set('unsavedChanges', true);
        this.notifyPropertyChange('object.conditions');
      },

      deleteCondition: function deleteCondition(index) {
        var object = this.get('object');

        var conditions = object.conditions;
        conditions.splice(index, 1);
        set(object, 'conditions', conditions);
        this.notifyPropertyChange('object.conditions');
      },

      setFilteredSources: function setFilteredSources(value, parentKey) {
        this.set('conditionSourceFilterInput', value);
        this.set('conditionSourceFilterKey', parentKey);
      },

      addConditionValue: function addConditionValue(options, index, values) {
        var object = this.get('object');
        var condition = get(object, 'conditions.' + index);

        set(object, 'conditions.' + index + '.data.' + condition.kind, values.map(function (v) {
          return v['key'] ? v['key'] : v;
        }));
        if (options) set(options, 'questionSourceRemaining', [].concat(_toConsumableArray(options.questionSource.filter(function (q) {
          return !values.some(function (v) {
            return q.key == v.key;
          });
        }))));
        this.set('unsavedChanges', true);
        this.notifyPropertyChange('object.conditions');
      },

      editElementName: function editElementName(value) {
        if (value !== -1) {
          this.set('elementName', value);
          this.set('object.name', value);
        }
        this.set('unsavedChanges', true);
      },

      updateElementContent: function updateElementContent(content, kind) {
        var object = this.get('object');
        var steps = this.get('steps');
        var stepIdx = this.get('selectedStepIndex');
        var elementIdx = this.get('selectedElementIndex');

        set(object, 'content', content);
        if (kind) set(steps[stepIdx].survey_elements_attributes[elementIdx], 'kind', kind);
        this.set('unsavedChanges', true);
      },

      setSelectedTab: function setSelectedTab(tabIndex) {
        this.set('selectedTabIndex', tabIndex);
      },

      cancelChanges: function cancelChanges() {
        var savedObject = copy(this.get('savedObject'), true);
        var object = this.get('object');

        set(object, 'conditions', savedObject.conditions);
        this.send('editElementName', savedObject.name);
        this.send('updateElementContent', savedObject.content);
        this.set('unsavedChanges', false);
        this.notifyPropertyChange('object.conditions');
      },

      saveChanges: function saveChanges(_) {
        var _steps = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

        var object = this.get('object');
        var saveCallback = this.get('group.autoSaveRevisionCallback');
        var stepIdx = this.get('selectedStepIndex');
        var elementIdx = this.get('selectedElementIndex');
        var steps = _steps ? _steps : this.get('steps');
        var changeSurveyTypeCallback = this.get('group.changeSurveyTypeCallback');
        var dispatcher = this.get('actionsDispatcher');

        if (object.kind === 'rating' && object.content.main_rating) {
          var scaleType = this.get('group.ratingScalesList').filter(function (rs) {
            return rs.id === object.content.rating_scale_id;
          })[0].get('rating_scale_type');
          if (scaleType !== this.get('survey.survey_type')) dispatcher(changeSurveyTypeCallback, { key: scaleType }, true);
        }

        set(steps[stepIdx], 'survey_elements_attributes.' + elementIdx, object);
        this.send('dispatchAction', saveCallback, steps);
        this.set('savedObject', copy(object, true));
      }
    }
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{v2/surveys-library/surveys/navbar/elements/elements-content}} alert component
 * @figma https://www.figma.com/file/oVZcAHwvRm3G31IY8K6PK9/Dashboard----Survey-Builder-%5BWIP%5D?type=design&node-id=2888-44430&t=Lr5wZmZtcPOSO3A0-0
 */