define('due-dashboard/components/due-table/elements/-variation-point-up', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var htmlSafe = _ember['default'].String.htmlSafe;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['report-array-number-trend', 'variation-point-up'],

    upColor: 'green',

    displayGreen: false,
    displayRed: false,

    pts: computed('value', function () {
      var value = this.get('value.variation');
      if (value != undefined && Math.abs(value) <= 1) {
        return this.get('i18n').t('words.pt');
      }
      return this.get('i18n').t('words.pts');
    }),

    compValue: computed('value', function () {
      var pts = this.get('pts');
      var value = this.get('value.variation');
      if (value == undefined) {
        return '-';
      } else if (value > 0) {
        this.get('upColor') == 'green' ? this.set('displayGreen', true) : this.set('displayRed', true);
      } else if (value < 0) {
        this.get('upColor') == 'green' ? this.set('displayRed', true) : this.set('displayGreen', true);
      }
      return value + ' ' + pts;
    })

  });
});